import router from "@/router/index";
import store from "./store";

/**
 * 
 * @param {*} to 
 * @returns 
 * 判断添加路由列表中是否存在 避免重复
 */

function hasRoute(to) {
    let find = router.getRoutes().find(item => item.name === to.name);
    return !!find;
}


export async function loadAsyncRoutes(next) {
    await store.dispatch('getMenuRole') // 请求权限列表
    await store.dispatch('getUserRole') // 请求用户角色信息keyword(查看用户)
    store.commit('setMenuLoad', true);

    const realRouter = store.getters.getRouteList // 获取路由列表信息

// console.log('真实路由', realRouter, '菜单列表', store.getters.getmenuList);

    if (realRouter.length > 0) {
        realRouter.map(item => {
            if (!hasRoute(item)) {
                router.addRoute('home', item)
            }
        })
    }
    if (!hasRoute({ name: '404' })) {
        router.addRoute(
            {
                path: '/:pathMatch(.*)*',
                name: '404',
                component: () => import(/* common */'@/components/404.vue'),
                meta: { title: '404' }
            },
        )
    }
}
