import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab.js'
import permission from './permission.js'
import menuList from "./menuList.js"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tab,
        permission,
        menuList
    }
})


