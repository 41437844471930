import { menuList } from "@/utils/asyncRoutes.js";

import { getMenus, userRoleList } from "@/api/role";
import { treeToArray, filterAsyncRoutes, asyncRoutes } from "@/utils/asyncRoutes.js";

export default {
    state: {
        menuList: [], // 用户菜单权限列表
        realRoute: [], // 权限路由列表
        menuLoad: false, //菜单是否已加载状态避免重复加载，刷新又将变为false。
        onlyViewRole: false, // 只读权限(keyword == 'view' 隐藏各模块操作栏按钮)
    },
    actions: {
        async getMenuRole({ commit }) {
            const { data } = await getMenus();
            commit('setMenuList', data); // 保存菜单
            commit('setRealRoute', data); // 保存权限
        },
        async getUserRole({ commit }) {
            const { userInfo } = JSON.parse(window.sessionStorage.getItem('userInfo'))[0];
            userRoleList(userInfo.id).then((Value) => {
                console.log(Value);
                const keyword = Value.data[0].keyword;
                commit('setOnlyViewRole', keyword == 'view')
            });
        }
    },
    getters: {
        getmenuList: state => state.menuList, // 获取用户权限
        getRouteList: state => state.realRoute,
        getMenuLoad: (state) => state.menuLoad,
        getOnlyViewRole: (state) => state.onlyViewRole
    },
    mutations: {
        setMenuLoad(state, menuLoad) {
            state.menuLoad = menuLoad
        },
        setMenuList(state, menuList) {
            state.menuList = menuList
        },
        setRealRoute(state, menuList) {
            const roles = treeToArray(menuList); // 所有有权限的数据
            const realRouter = filterAsyncRoutes(asyncRoutes, roles); //layout下 动态路由信息==对比过滤
            state.realRoute = realRouter;
        },
        setOnlyViewRole(state, onlyViewRole) {
            state.onlyViewRole = onlyViewRole
        },
        // 退出登录时 清空菜单列表所有数据
        resetmeneData(state) {
            state.menuList = []
            state.realRoute = []
            state.menuLoad = false
        }
    }
}