import { request } from "@/utils/request"

/**
 * 
 *角色管理接口
 */

//角色列表
export function RoleList(data, pagenum, size) {
    return request({
        url: `/api/user/role/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}


//通过userId查角色列表
export function userRoleList(id) {
    return request({
        url: `/api/user/role/getByUserId/${id}`,
        method: 'get',
    })
}

//新增角色
export function addRole(data) {
    return request({
        url: `/api/user/role/add`,
        method: 'post',
        data
    })
}

//编辑角色
export function updateRole(data, id) {
    return request({
        url: `/api/user/role/update/${id}`,
        method: 'put',
        data
    })
}

//删除角色
export function deleteRole(id) {
    return request({
        url: `/api/user/role/delete/${id}`,
        method: 'delete',
    })
}


//绑定权限--批量
export function bindRolePermint(data) {
    return request({
        url: `/api/user/role/bindPermits`,
        method: 'post',
        data
    })
}

//解绑权限
export function unbindRolePermint(data) {
    return request({
        url: `/api/user/role/unBindPermit`,
        method: 'post',
        data
    })
}


// 用户菜单列表权限
export function getMenus(data) {
    return request({
        url: `api/user/menu/list`,
        method: 'get'
    })
}

// 查询用户菜单权限数据
export function getRoleMenu(data) {
    return request({
        url: `api/user/menu/getByRoleId`,
        method: 'post',
        data
    })
}

// 分配角色--菜单权限
export function bindRoleMenus(data) {
    return request({
        url: `api/user/role/bindMenus`,
        method: 'post',
        data
    })
}