import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// 引入nprogress 进度条插件
import NProgress from 'nprogress'

import { asyncRoutes } from '@/utils/asyncRoutes'
import { loadAsyncRoutes } from '@/permission'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由懒加载  import(/* webpackChunkName: "property" */ '../components/property.vue')

Vue.use(VueRouter)

export const routes = [
  {
    path: '/', redirect: '/login'
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login_home" */ '../components/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/dashboard',
    // redirect: '/property',
    component: () => import(/* webpackChunkName: "login_home" */ '../components/Home.vue'),
    children: []
  },
  // {
  //   path: '/404',
  //   component: () => import(/* webpackChunkName: "404" */ '../components/404.vue'),
  //   hidden: true
  // },
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }

]

const router = new VueRouter({
  routes
})

// 路由导航守卫控制---访问权限
router.beforeEach(async (to, from, next) => {
  // 开启进度条
  NProgress.start()

  let routeFlag = store.getters.getMenuLoad;
  // console.log('异步路由标识', store.getters.getMenuLoad);

  //   //to将要访问的路径
  //   //from从那个路径跳转而来
  //   //next函数 执行下一步 next()或next('/login)
  //   console.log(to);
  // if (to.path === '/login') return next()

  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) {
    if (to.path === '/login') {
      next();
      NProgress.done()
      return
    } else {
      next('/login')
      NProgress.done()
      return
    }
  } else {
    // 用户权限守卫
    // const result = JSON.parse(
    //   window.sessionStorage.getItem('userInfo')
    // )[0].userInfo.hasAdmin
    // if (!result && to.meta.type == 'admin') {
    //   // console.log('无权限');
    //   alert('您无权限访问该页面')
    //   next(from.path)
    //   return
    // }
    // 登录后手动跳转 登录页 == 退出登录
    if (to.path === '/login') {
      resetRouter();
      window.sessionStorage.clear();
      store.commit("clearTaglist");
      next()
      NProgress.done()
      return;
    } else {
      next();
    }

    // if (to.matched.length === 0) return next('/404');
    // console.log(from)

    // console.log(store.state.tab.tagList);
    const result = store.state.tab.tagList.findIndex(item => item.url === to.path)
    // if (result == -1) {
    //   console.log('不存在');

    // } else {
    //   console.log('存在');
    // }

    const MenuItem = {
      name: '',
      url: ''
    }
    const activePath = to.path
    if (activePath == '/property') {
      (MenuItem.name = '资产管理'), (MenuItem.url = activePath)
    } else if (activePath == '/lease') {
      (MenuItem.name = '租赁合同管理'), (MenuItem.url = activePath)
    } else if (activePath == '/stockreport') {
      (MenuItem.name = '存量报表'), (MenuItem.url = activePath)
    } else if (activePath == '/addcarinfo') {
      (MenuItem.name = '新增资产管理'), (MenuItem.url = activePath)
    } else if (activePath == '/editcarinfo') {
      (MenuItem.name = '编辑资产管理'), (MenuItem.url = activePath)
    } else if (activePath == '/user') {
      (MenuItem.name = '用户管理'), (MenuItem.url = activePath)
    } else if (activePath == '/role') {
      (MenuItem.name = '角色管理'), (MenuItem.url = activePath)
    } else if (activePath == '/permit') {
      (MenuItem.name = '权限管理'), (MenuItem.url = activePath)
    } else if (activePath == '/settlereport') {
      (MenuItem.name = '结清报表'), (MenuItem.url = activePath)
    } else if (activePath == '/propertypercent') {
      (MenuItem.name = '资产占比'), (MenuItem.url = activePath)
    } else if (activePath == '/settleearnings') {
      (MenuItem.name = '结清收益'), (MenuItem.url = activePath)
    } else if (activePath == '/exceptional') {
      (MenuItem.name = '异常资产'), (MenuItem.url = activePath)
    } else if (activePath == '/customizedbill') {
      (MenuItem.name = '定制账单'), (MenuItem.url = activePath)
    } else if (activePath == '/billrecord') {
      (MenuItem.name = '账单管理'), (MenuItem.url = activePath)
    } else if (activePath == '/reviewLease') {
      (MenuItem.name = '合同审核管理'), (MenuItem.url = activePath)
    } else if (activePath == '/provePayment') {
      (MenuItem.name = '核销管理'), (MenuItem.url = activePath)
    } else if (activePath == '/renderResource') {
      (MenuItem.name = '进件管理'), (MenuItem.url = activePath)
    } else if (activePath == '/dashboard') {
      (MenuItem.name = '首页'), (MenuItem.url = activePath)
    }
    if (to.path != '' && activePath != '/login' && activePath != '/home') store.commit('ChangeTags', MenuItem)

    // 守卫itcarinfo页面
    if (to.path == '/editcarinfo' && to.params.assetsId == undefined && result == -1) {
      console.log('非进入编辑页面')
      store.commit('closeTab', MenuItem)
      return next('/property')
    }

    // 加载异步路由
    if (routeFlag) return next()
    await loadAsyncRoutes()
    next()
  }

})
// 重置动态路由, 保留基础路由
export function resetRouter() {
  // 重置router对象
  router.matcher = new VueRouter({ routes }).matcher
  // 清除路由状态信息
  store.commit("resetmeneData");
}


router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})
export default router
